import MediaControlsComponent from '@wix/thunderbolt-elements/src/components/VideoBoxContainer/VideoBoxAudio/viewer/VideoBoxAudio';


const MediaControls = {
  component: MediaControlsComponent
};


export const components = {
  ['MediaControls']: MediaControls
};


// temporary export
export const version = "1.0.0"
